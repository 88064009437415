import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Title from './Title';

const Experience = () => {
  return (
    <section id="projects">
      <Container>
        <Title title="Experience" />
        <Carousel interval={null} className="carousel">
          <Carousel.Item>
            <Fade>
              <h3 className="project-wrapper__text-title-centered">
                Junior Software Engineer
                <b className="project-wrapper__text-title-colored"> @ ShopGrok</b>
              </h3>
              <p className="project-wrapper__text-date">Mar. 2021 - Present</p>
              <p>
                <ul>
                  <li className="items">
                    Created more than 20 web scrapers for FMCG websites, extracting upwards of
                    100,000 price points and relevant product data using tools such as requests,
                    XPaths and Splash
                  </li>
                  <li className="items">
                    Created a product match application from scratch using React + Material UI
                    front-end and Flask back-end (secured with HTTP basic auth) that connects to
                    Snowflake DB. Integrated tableau dashboards using Tableau javascript API. CRUD
                    application that can edit or delete more than 200,000 data points. Deployed on
                    Google App Engine
                  </li>
                  <li className="items">
                    Clients include Dan Murphy&apos;s, BWS, Rebel, Michael Hill and Super Retail
                    Group
                  </li>
                </ul>
              </p>
            </Fade>
          </Carousel.Item>
          <Carousel.Item>
            <Fade>
              <h3 className="project-wrapper__text-title-centered">
                Academic Tutor
                <b className="project-wrapper__text-title-colored"> @ EKC</b>
              </h3>
              <p className="project-wrapper__text-date">Sep. 2016 - Mar. 2021</p>
              <p>
                <ul>
                  <li>
                    Tutor a variety of small to medium classes ranging from Year 2 to Year 12
                    including HSC and Preliminaries
                  </li>
                  <li>
                    Provide 1 on 1 consultation with parents to reinforce student&apos;s progress
                    and provide feedback on their test results
                  </li>
                  <li>Mark upwards of 50 homework books in a two hour timeframe</li>
                </ul>
              </p>
            </Fade>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
};

export default Experience;
