import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import Title from './Title';
import ProjectImg from './Image/ProjectImg';

const projects = [
  {
    id: nanoid(),
    img: 'churn.png',
    title: 'Customer Attrition Predictor',
    info: `Predicts likelihood of customer churn for banks. Uses multiple regression as machine 
          learning model. Utilised React, Flask, Axios, Pandas, Numpy, Scikit-Learn, Heroku
          and Firebase. REST application.`,
    url: 'https://futurespace.seanskggo.com',
    repo: 'https://github.com/seanskggo/futurespace',
  },
  {
    id: nanoid(),
    img: 'vscode.png',
    title: 'Bule Syntax Highlighter',
    info: `Simple VS Code syntax highlighter for Bule, a SAT programming language. 
    Created with VS Code API and CLI tools.`,
    url: 'https://marketplace.visualstudio.com/items?itemName=seanskggo.bule-syntax-highlighting',
    repo: 'https://github.com/seanskggo/bule-syntax-highlighter',
  },
  {
    id: nanoid(),
    img: 'TicTacToe.png',
    title: 'Tic Tac Toe AI App',
    info: `Mobile app created with React Native and Expo from scratch using functional components, 
          hooks and Minimax algorithm. Published on Google Play`,
    url: 'https://play.google.com/store/apps/details?id=dev.quikk.tictactoeai',
    repo: 'https://github.com/seanskggo/tictactoe',
  },
  {
    id: nanoid(),
    img: 'personal.png',
    title: 'Portfolio Ver. 1',
    info: `Responsive personal website created with React and React Bootstrap from scratch using state hooks.
          Includes routing with react-router-dom as well as lazy loading with react viewport.`,
    url: 'https://v1.seanskggo.com',
    repo: 'https://github.com/seanskggo/portfolio-v1',
  },
  {
    id: nanoid(),
    img: 'contacts.png',
    title: 'CRUD Contacts App',
    info: 'Basic CRUD contacts application. Created with React and React-bootstrap components.',
    url: 'https://contacts.seanskggo.com',
    repo: 'https://github.com/seanskggo/contacts',
  },
];

const Projects = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Projects" />
          {projects.map((project) => {
            const { title, info, url, repo, img, id } = project;
            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title}</h3>
                      <div>
                        <p>{info}</p>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url}
                      >
                        See Live
                      </a>

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
