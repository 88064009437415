import React from 'react';
import Hero from './Hero';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import Footer from './Footer';
import Experience from './Experience';
import CodeProjects from './CodeProjects';

function App() {
  return (
    <>
      <Hero />
      <About />
      <Experience />
      <Projects />
      <CodeProjects />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
