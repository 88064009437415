import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../content/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export const headData = {
  title: 'Sean Go',
  description: "Sean's Personal Portfolio",
};

export default () => {
  const { title, description } = headData;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <html lang="en" />
        <meta name="description" content={description} />
      </Helmet>
      <App />
    </>
  );
};
