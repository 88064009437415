import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { nanoid } from 'nanoid';

const networks = [
  { id: nanoid(), name: 'linkedin', url: 'https://linkedin.com' },
  { id: nanoid(), name: 'github', url: 'https://github.com/seanskggo' },
  { id: nanoid(), name: 'gitlab', url: 'https://gitlab.cse.unsw.edu.au/z5310199' },
];

const Footer = () => {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a key={id} href={url} rel="noopener noreferrer" target="_blank" aria-label={name}>
                  <i className={`fa fa-${name} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">© {new Date().getFullYear()} - Sean Go </p>
      </Container>
    </footer>
  );
};

export default Footer;
