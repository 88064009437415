import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from './Title';
import Resume from '../pdf/resume.pdf';

const aboutText = () => {
  return (
    <div>
      <p>
        I am a 20-year old full-stack software engineer based in Sydney. I am also currently a
        penultimate student studying bachelor of Computer Science at UNSW.
      </p>
      <p>
        My main interests and skills lie within web development and data analytics (including
        machine learning), however, I am also experienced with networks, assembly languages and app
        development.
      </p>
    </div>
  );
};

const About = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);
  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper__about">
          <Col md={7} sm={10}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">{aboutText()}</p>
                <span className="d-flex mt-3 middle">
                  <a
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    target="_blank"
                    href={Resume}
                  >
                    Resume
                  </a>
                </span>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
