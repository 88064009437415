import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import Fade from 'react-reveal/Fade';
import Title from './Title';

const codeProjects = [
  {
    id: nanoid(),
    title: 'Text Analyser',
    tech: 'C, makefile, Bash',
    p1: `Text analyser created with C using an AVL tree with a complete search and display 
          time of O(nlogn)`,
    p2: `Searches a large text file for the most frequent words and their count in 
          lexicographical  order`,
    p3: `Accounts for stemmed words using Porter Stemming Algorithm`,
    p4: ``,
    url: 'https://github.com/seanskggo/text-analyser',
  },
  {
    id: nanoid(),
    title: 'TCP Web Server',
    tech: 'Python, Socket, HTML',
    p1: `TCP Server created with python sockets that parses browser GET requests
          and returns relevant html/png responses`,
    p2: `Follows HTTP protocol - returns any existing html/png files in current directory
          with 200 status if run from localhost`,
    p3: ``,
    p4: ``,
    url: 'https://github.com/seanskggo/tcp-server',
  },
  {
    id: nanoid(),
    title: 'Digit Classifier',
    tech: 'Pandas, Numpy, Tensorflow, Keras',
    p1: `Simple handwritten digit classifier created using Tensorflow and documented on 
          Jupyter Notebook`,
    p2: `Training model is based on default MNIST dataset from Keras`,
    p3: `Demonstration of simple Convolutional Neural Network`,
    p4: ``,
    url: 'https://github.com/seanskggo/digit-recognition',
  },
  {
    id: nanoid(),
    title: 'Tic Tac Toe GUI',
    tech: 'Python, PyQt, Qt designer, NSIS',
    p1: `Tic Tac Toe GUI created with PyQt using Qt Designer`,
    p2: `Includes an AI created with Python using Minimax algorithm which also supports
          customisation of board images`,
    p3: `Package manager for Windows created with NSIS`,
    p4: ``,
    url: 'https://github.com/seanskggo/tictactoe-pyqt',
  },
];

const Experience = () => {
  return (
    <section id="codeProjects">
      <Container>
        <Title title="Code-Based Projects" />
        <Carousel interval={null} className="carousel">
          {codeProjects.map((ele) => {
            const { id, tech, title, p1, p2, p3, p4, url } = ele;
            return (
              <Carousel.Item id={id}>
                <Fade>
                  <h3 className="project-wrapper__text-title-centered">
                    <div style={{ paddingBottom: '8px' }}>{title}</div>
                    <p className="project-wrapper__text-title-colored">{tech}</p>
                    <div style={{ paddingBottom: '1px' }} />
                  </h3>
                  <p>
                    <ul>
                      {p1 && <li className="items">{p1}</li>}
                      {p2 && <li className="items">{p2}</li>}
                      {p3 && <li className="items">{p3}</li>}
                      {p4 && <li className="items">{p4}</li>}
                    </ul>
                  </p>
                  <div className="project-wrapper__text-title-centered">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn text-color-main"
                      href={url}
                    >
                      Source Code
                    </a>
                  </div>
                </Fade>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Container>
    </section>
  );
};

export default Experience;
